import { render, staticRenderFns } from "./admin.html?vue&type=template&id=110f7287&scoped=true&external"
import script from "./admin.ts?vue&type=script&lang=ts&external"
export * from "./admin.ts?vue&type=script&lang=ts&external"
import style0 from "./admin.scss?vue&type=style&index=0&id=110f7287&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "110f7287",
  null
  
)

/* custom blocks */
import block0 from "./admin.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2FsuperAdmin%2Fadmin%2Fadmin.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VDataTable,VIcon,VProgressLinear,VRow,VTextField})
