import { OdataItems } from '@/shared/model/OdataItems';
import organisation, { Organisation } from '@/shared/model/organisation';
import { OrganisationUser } from '@/shared/model/organisationUser';
import DateUtils from '@/shared/utils/DateUtils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, namespace } from 'vuex-class';
import CreateCompanyView from './create-company/create-company.vue';
import AddDaysDialog from './add-days-dialog/add-days-dialog.vue';
import user, { User } from '@/shared/model/user';
import UserSubscriptionsInfoDialog from '@/views/settings/subscriptions/user-subscriptions-info/user-subscriptions-info-edit/user-subscriptions-info-edit.vue';
import userBillingInfo, { UserBillingInfo } from '@/shared/model/userBillingInfo';
import { Dictionary } from 'vue-router/types/router';

const organizationModule = namespace('organization');
const userBillingInfoModule = namespace('userBillingInfo');
const userModule = namespace('user');
const authModule = namespace('auth');

@Component({
  components: { CreateCompanyView, UserSubscriptionsInfoDialog, AddDaysDialog },
})
export default class AdminView extends Vue {
  @organizationModule.Action('getMainOrganisations') //TODO use single procedure for all cases??
  private getMainOrganizations!: any;
  @organizationModule.Getter('getMainOrganizations')
  private mainOrganizations!: any;
  @organizationModule.Action('getOrganisationsForMain') //TODO use single procedure for all cases??
  private getOrganizationsForMain!: any;
  @organizationModule.Getter('getOrganizationsForMain')
  private organizationsForMain!: any;
  @organizationModule.Getter('gobdBlueIsProofed')
  private gobdBlueIsProofed!: any;

  @organizationModule.Action('getOrganizationsAll')
  private actionGetOrganizationsAll!: any;
  @organizationModule.Getter('getOrganizations')
  private getOrganizations!: any;

  @organizationModule.Action('getOrganisationUsersAdmPage')
  private getOrganisationUsers!: any;
  @organizationModule.Getter('getOrganizationUsersAdmPage')
  private organisationUsers!: Dictionary<OdataItems<OrganisationUser>>;

  private organisationTotalUsersWithSubOrgs(id: string) {
    const orgs = this.getOrganizations.items.filter((x: Organisation) => x.owner.mainOrganisationId == id);
    let i = 0;
    orgs.forEach((element: Organisation) => {
      i = i + element.activeUsersCount;
    });
    return i;
  }

  private organisationTotalUsers(id: string) {
    const orgs = this.mainOrganizations.items.filter((x: Organisation) => x.owner.mainOrganisationId == id);
    let i = 0;
    orgs.forEach((element: Organisation) => {
      i = i + element.activeUsersCount;
    });
    return i;
  }

  percent(size: number, maximum: number) {
    return ((size * 100) / maximum).toFixed(2);
  }

  demoCountdown(item: Organisation) {
    return item.isDemo ? this.$t('remaining_days') + this.dateDiff(item.createdAt) : '';
  }

  dateDiff(date: string) {
    const dateStart = new Date(date);
    const dateEnd = new Date();
    const daydiff = (dateEnd.getTime() - dateStart.getTime()) / (1000 * 60 * 60 * 24);
    return (30 - daydiff).toFixed(0);
  }

  mainCompanySpace(item: Organisation) {
    const isRegistered = item.kundenNummer > 0;
    const hdds = item.totalSpace * 200; //addonHddPackageCount * addonHddPackageVolume
    //return isRegistered ? item.totalSpaceForMain * 200 + 100 + hdds : item.totalSpaceForMain * 100 + hdds;
    return 200 + hdds; //mainHddPackageVolume + hdds
  }

  companySpace(item: Organisation) {
    const isMain = item.organisationId == item.owner.mainOrganisationId;
    const isRegistered = item.kundenNummer > 0;
    const hdds = item.totalSpace * 200;
    //if (isMain) return isRegistered ? 200 + 100 + hdds : 100 + hdds;
    //else return isRegistered ? 200 + hdds : 100 + hdds;
    return 100 + hdds;
  }

  @Watch('mainOrganizations.searchParams', { deep: true })
  public onMainOrganizationsOptionsChanged(newVal: any, oldVal: any) {
    this.getMainOrganizations();
  }
  private itemsPerPageOptions: number[] = [50, 100, 250, 500];

  public onOrganizationsForMainOptionsChange(
    payload: {
      page: number;
      itemsPerPage: number;
      sortBy: string[];
      sortDesc: boolean[];
      groupBy: string[];
      groupDesc: boolean[];
      multiSort: boolean;
      mustSort: boolean;
    },
    item: any
  ) {
    this.getOrganizationsForMain(item.id);
  }
  public async onOrganisationUsersOptionsChange(
    payload: {
      page: number;
      itemsPerPage: number;
      sortBy: string[];
      sortDesc: boolean[];
      groupBy: string[];
      groupDesc: boolean[];
      multiSort: boolean;
      mustSort: boolean;
    },
    item: any
  ) {
    await this.getOrganisationUsers(item.id);
  }
  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('customer_number'), value: 'owner.kundenNummer' },
      { text: this.$t('customer'), value: 'name' },
      { text: this.$t('first_name'), value: 'owner.firstName' },
      { text: this.$t('last_name'), value: 'owner.lastName' },
      { text: this.$t('registration_date'), value: 'createdAt' },
      { text: this.$t('storage_space'), value: 'filesSize' },
      { text: this.$t('actions'), value: 'actions', sortable: false, width: '1%' },
    ];
    return headers;
  }
  expanded = [];

  get headers2() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('organisation'), value: 'name' },
      { text: this.$t('id'), value: 'organisationId' },
      { text: this.$t('registration_date'), value: 'createdAt' },
      { text: this.$t('storage_space'), value: 'filesSize' },
      { text: this.$t('actions'), value: 'actions', sortable: false, width: '1%' },
    ];
    return headers;
  }
  expanded2 = [];

  get headers3() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('email'), value: 'email' },
      { text: this.$t('first_name'), value: 'firstName' },
      { text: this.$t('last_name'), value: 'lastName' },
      { text: this.$t('short_name'), value: 'shortName' },
      { text: this.$t('id'), value: 'userId' },
      { text: this.$t('actions'), value: 'actions', sortable: false, width: '1%' },
    ];
    return headers;
  }
  expanded3 = [];

  mounted() {
    // this.getMainOrganizations().then(() => {});
  }

  dateISOToLocal(isoDate: any) {
    return DateUtils.isoDateToScreenDateWithLocale(isoDate, this.$i18n.locale, false, true);
  }

  //create company
  private isSaveBtnLoading = false;

  onClickAdd() {
    this.itemEditDialog.model = { ...user.parse({}) };
    this.itemEditDialog.show = true;
  }

  async onClickEditt(item: User) {
    this.itemEditDialog.model = { ...user.parse(item) };
    this.itemEditDialog.show = true;
  }

  itemEditDialog = {
    show: false,
    model: { ...user.parse({}) },
  };

  async itemEditDialogOnUpdate(user: User, created: boolean, orderDirect: boolean, userOrganisationId: string) {
    this.itemEditDialog.show = false;
    this.getMainOrganizations().then(() => {});
    if (created && orderDirect) {
      this.userBillingInfoEditDialog.organization.id = userOrganisationId;
      this.userBillingInfoEditDialog.organization.isDemo = true;

      this.onEdit(userBillingInfo.parse({ organisationId: userOrganisationId, userId: user.id }));
    }
  }

  async itemEditDialogOnClose() {
    this.isSaveBtnLoading = false;
    this.itemEditDialog.show = false;
  }

  //add days dialog
  addDaysOrganisationId = '';
  increaseDemoPeriod(organisationId: string) {
    this.addDaysOrganisationId = organisationId;
    this.onClickAddDays();
  }

  onClickAddDays() {
    this.addDaysDialog.model = '0';
    this.addDaysDialog.show = true;
  }

  async onClickEdittDays(item: User) {
    this.addDaysDialog.model = '0';
    this.addDaysDialog.show = true;
  }

  addDaysDialog = {
    show: false,
    model: '0',
  };

  async addDaysDialogOnUpdate(days: string) {
    this.addDaysDialog.show = false;
    await this.addDemoDays({ organizationId: this.addDaysOrganisationId, days: days }).then(() => {
      this.addDaysOrganisationId = '';
      this.getMainOrganizations();
    });
  }

  async addDaysDialogOnClose() {
    this.addDaysOrganisationId = '';
    this.addDaysDialog.show = false;
  }

  // billing
  @organizationModule.Action('updateToMain')
  private updateToMain!: any;
  @organizationModule.Getter('getInProgressUpdateToMain')
  private inProgressUpdateToMain!: any;

  userBillingInfoEditDialog = {
    show: false,
    model: { ...userBillingInfo.parse({}) },
    organization: { ...organisation.parse({}) },
  };

  async userBillingInfoEditDialogOnClose(userBillingInfo: UserBillingInfo) {
    this.userBillingInfoEditDialog.show = false;
  }

  async userBillingInfoEditDialogOnUpdate(userBillingInfo: UserBillingInfo) {
    this.userBillingInfoEditDialog.show = false;
    this.updateToMain({ organisationId: userBillingInfo.organisationId, userBillingInfo: userBillingInfo }).then(() => {
      this.getMainOrganizations();
    });
  }

  onEdit(userBillingInfo: UserBillingInfo) {
    this.userBillingInfoEditDialog.model = { ...userBillingInfo };
    this.userBillingInfoEditDialog.show = true;
  }

  //organisation settings
  @organizationModule.Action('updateOrganizationSettings')
  private updateOrganizationSettings!: any;

  @organizationModule.Action('deleteAndCleanAllTables')
  private deleteAndCleanAllTables!: any;

  @organizationModule.Action('export')
  private export!: any;

  @organizationModule.Action('treatGobdEnable')
  private treatGobdEnable!: any;

  @organizationModule.Action('addDemoDays')
  private addDemoDays!: any;

  //TODO not used at this time?
  clickOrgBlockSwitch(organizationId: string) {
    this.updateOrganizationSettings({ organizationId: organizationId, name: 'block_switch' }).then(() => {
      this.getMainOrganizations();
    });
  }

  clickOrgProcessListSwitch(item: Organisation) {
    this.updateOrganizationSettings({ organizationId: item.organisationId, name: 'process_list_switch' }).then(() => {
      this.getOrganizationsForMain(item.owner.mainOrganisationId);
    });
  }
  clickTreatGobdEnableForOrganization(item: Organisation) {
    this.treatGobdEnableForOrganization(item.organisationId).then(() => {
      item.gobdBlueIsProofed = !item.gobdBlueIsProofed;
    });
  }

  clickOrgDocumentFilesExportSwitch(item: Organisation) {
    this.updateOrganizationSettings({ organizationId: item.organisationId, name: 'document_files_export_switch' }).then(
      () => {
        this.getOrganizationsForMain(item.owner.mainOrganisationId);
      }
    );
  }

  private async clickOrgDeleteSwitch(item: any) {
    const isMainOrg = item.organisationId == item.owner.mainOrganisationId;

    this.$confirm
      .open(
        `${this.$t('organization_actions.delete_title')}`,
        `${this.$t('organization_actions.delete_message', {
          0: item.organisationId,
          1: item.name,
          2: isMainOrg ? `${this.$t('organization_actions.delete_warning')}` : '',
        })}`,
        {
          cancelText: this.$t('dialogs.confirmation.noBtn'),
          okText: this.$t('dialogs.confirmation.yesBtn'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          this.deleteAndCleanAllTables(item.organisationId).then(() => {
            this.getOrganizationsForMain(item.owner.mainOrganisationId);
          });
        } else {
        }
      });
  }

  private async treatGobdEnableForOrganization(organizationId: string) {
    this.treatGobdEnable({ organizationId: organizationId }).then(() => {});
  }

  private async exportOrganization(organizationId: string) {
    this.export({ organizationId: organizationId }).then(() => {});
  }

  //user settings
  @userModule.Action('updateUser')
  private updateUser!: any;

  @authModule.Action('impersonateUser')
  private actionImpersonateUser!: any;

  @authModule.Action('login')
  private actionLogin!: any;
  @authModule.Action('logout')
  private actionLogout!: any;

  private async clickUserBlockSwitch(userId: string, isDisabled: boolean) {
    await this.updateUser({ id: userId, disabled: !isDisabled }).then(async () => {
      await this.getMainOrganizations();
      // await this.getUsersAll();
    });
  }
  @userModule.Action('moveToRecycle')
  private moveToRecycle!: any;
  @userModule.Action('restoreFromRecycle')
  private restoreFromRecycle!: any;

  private async clickUserDeleteSwitch(user: any) {
    if (user.deleted) {
      await this.restoreFromRecycle(user.userId);
      await this.getMainOrganizations();
      // await this.getUsersAll();

      return;
    }

    this.$confirm
      .open(
        `${this.$t('user_actions.delete_title')}`,
        `${this.$t('user_actions.delete_message', {
          0: user.userId,
          1: user.firstName + ' ' + user.lastName,
        })}`,
        {
          cancelText: this.$t('dialogs.confirmation.noBtn'),
          okText: this.$t('dialogs.confirmation.yesBtn'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.moveToRecycle(user.userId);
          await this.getMainOrganizations();
          // await this.getUsersAll();
        }
      });
  }

  @authModule.Action('signinRedirectCallback')
  private signinRedirectCallback!: any;
  private async impersonateUser(userId: string, organizationId: string) {
    this.actionLogin({
      organizationId: organizationId,
      impersonateId: userId,
    });
  }

  //main organisation settings
  @userModule.Action('unlockAccess')
  private unlockAccess!: any;

  @userModule.Action('lockAccess')
  private lockAccess!: any;

  @organizationModule.Action('updateOrganizationForCustomerSettings')
  private updateOrganizationForCustomerSettings!: any;

  private async clickUserAllBlock(item: any) {
    await this.lockAccess({ id: item.organisationId }).then(async () => {
      await this.getMainOrganizations();
      await this.getOrganisationUsers(item.organisationId);
    });
  }

  private async clickUserAllUnBlock(item: any) {
    await this.unlockAccess({ id: item.organisationId }).then(async () => {
      await this.getMainOrganizations();
      await this.getOrganisationUsers(item.organisationId);
    });
  }

  private async changeDeleteStatusCustomer(item: any) {
    this.$confirm
      .open(
        `${this.$t('main_organization_actions.delete_title')}`,
        `${this.$t('main_organization_actions.delete_message', {
          0: item.name,
          1: item.owner.email,
        })}`,
        {
          cancelText: this.$t('dialogs.confirmation.noBtn'),
          okText: this.$t('dialogs.confirmation.yesBtn'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          if (item.isDemo) {
            this.deleteAndCleanAllTables(item.organisationId).then(() => {
              this.getMainOrganizations();
            });
          }
        } else {
        }
      });
  }
}
