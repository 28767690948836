import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import { ConfirmEmailDto, EmailData, EmailType } from '@/shared/model/smallPayloadModels/emailData';
import user, { User } from '@/shared/model/user';
import { debounce, debounceAsync } from '@/shared/utils/generalUtils';

const logger = new Logger('registration');
const userModule = namespace('user');
const authModule = namespace('auth');

@Component({
  components: {},
})
export default class CreateCompanyView extends Vue {
  @Ref('registration-form')
  private refRegistrationForm!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  @userModule.Action('isUserEmailExist')
  private isUserEmailExist!: any;
  @userModule.Getter('getIsUserEmailExistInProgress')
  private isUserEmailExistInProgress!: any;
  @userModule.Action('sendEmail')
  private actionSendEmail!: any;
  @userModule.Action('confirmEmail')
  private actionConfirmEmail!: any;
  @userModule.Action('registerUser')
  private actionRegisterUser!: any;
  @userModule.Getter('getRegisterUserInProcess')
  private registerUserInProcess!: any;

  private userModel: User = user.parse({});

  email = '';
  isFormValid = true;
  isSubmitted = false;

  isUserRegistered = false;
  userExistError = false;

  userOrganisationId = '';

  password = '';
  confirmPassword = '';
  showPassword = false;
  showConfirmPassword = false;

  customerFirstName = '';
  customerLastName = '';
  customerPhone = '';

  agreeWithTerms = false;
  agreeWithDataProcessing = false;

  private orderDirect: boolean = false;

  //#region EmailConfirm Logic
  isEmailConfirmed = false;
  confirmEmailData: ConfirmEmailDto = {
    code: '',
    email: '',
  };
  @Watch('dialog')
  onFormShown(oVal: boolean, nVal: boolean) {
    this.refRegistrationForm?.resetValidation();
  }
  async mounted() {
    this.confirmEmailData.code = this.$route.query?.code?.toString();
    this.confirmEmailData.email = this.$route.query?.email?.toString();

    if (this.confirmEmailData.code && this.confirmEmailData.code) {
      await this.confirmEmail();
    }

    this.userModel = user.parse({});
    this.orderDirect = false;
    this.userOrganisationId = '';
  }

  async onEmailChange(event: any) {
    if (/^[^@]+@[^@]{2,}\.[^@]{2,}$/.test(event)) {
      this.userExistError = false;
      await this.isUserEmailExist(this.email).then((result: { result: boolean }) => {
        this.userExistError = result.result;
      });
    }
  }
  async confirmEmail() {
    await this.actionConfirmEmail(this.confirmEmailData)
      .then((result: any) => {
        this.isEmailConfirmed = true;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  //#endregion

  //#region Validation rules
  private strongRegex = new RegExp('^(?=.*[a-z,ä,ö,ü])(?=.*[A-Z,Ä,Ö,Ü])(?=.*[0-9])(?=.{8,})'); // same rules as at `https://neu.easy-docs.de/`  (without special characters)

  get rules() {
    return {
      required: (value: any) => !!value || this.$t('messages.password_required'),
      requiredField: (value: any, field: any) => !!value || this.$t('messages.required', { 0: field }),
      strongPassword: (value: any) => (!!value && this.strongRegex.test(value)) || this.$t('messages.password_strong'),
      samePass: (value: any) =>
        (!!value && value && this.password == this.confirmPassword) || this.$t('messages.same_password'),
      requiredTermsCheckbox: (value: any) => !!value || this.$t('messages.terms_checkbox_required'),
      requiredDataCheckbox: (value: any) => !!value || this.$t('messages.data_checkbox_required'),
    };
  }

  get emailRules() {
    return [
      (v: string) => {
        return this.emailValidation(v) || this.$i18n.t('messages.email_required'); // i18n.tc(`messages.email_required`) is not working here, just shows `name_required_message` key
      },
    ];
  }

  emailValidation(v: string) {
    return (
      v &&
      v.trim().length >= 6 &&
      v.trim().length < 256 && // with domain minimum 6 chars (https://stackoverflow.com/a/9034077)
      /^[^@]+@[^@]{2,}\.[^@]{2,}$/.test(v) &&
      this.isUserEmailExistInProgress == false
    ); // simple validation because of https://stackoverflow.com/a/202528 (possible typos and made-up emails)
  }
  //#endregion
  @Watch('email', { deep: true })
  onEmailUpdated(nVal: any, oVal: any) {
    this.userModel.iKnowThatUserExist = false;
  }
  async registerUser() {
    const isFormValidResult = await this.refRegistrationForm.validate();

    if (isFormValidResult) {
      this.userModel.email = this.email;
      this.userModel.firstName = this.customerFirstName;
      this.userModel.lastName = this.customerLastName;
      this.userModel.phoneNumber = this.customerPhone;
      this.userModel.password = this.password;
      this.userModel.language = this.$root.$i18n.locale.toLocaleLowerCase();
      if (this.userExistError)
        this.userModel.iKnowThatUserExist = await this.$confirm.open(
          `${this.$t('create_existent_user_dialog_title')}`,
          `${this.$t('create_existent_user_dialog_text', { 0: this.userModel.email })}`,
          {
            cancelText: `${this.$t('cancel')}`,
            okText: this.$t('create'),
          }
        );
      if ((this.userExistError && this.userModel.iKnowThatUserExist) || !this.userExistError)
        await this.actionRegisterUser(this.userModel)
          .then(async (result: any) => {
            this.userExistError = false;
            //this.isUserRegistered = true;
            this.userOrganisationId = result.mainOrganizationId;
            this.userModel.id = result.id;
            this.userModel.mainOrganisationId = result.mainOrganizationId;
            this.userModel.iKnowThatUserExist;
            this.$emit('click:update', this.userModel, true, this.orderDirect, this.userOrganisationId);
            await this.sendEmail();
          })
          .catch((err: any) => {
            if (err.response.status === 409) {
              // 409 is Conflict error
              this.userExistError = true;
              this.userModel.iKnowThatUserExist = true;
            }
            logger.error(err);
          });
    }
  }

  private isEmailSending: boolean = false;
  async sendEmail() {
    const payload: EmailData = {
      userEmail: this.email,
      phone: '',
      type: EmailType.registration,
      siteHost: window.location.host,
    };
    this.isEmailSending = true;
    await this.actionSendEmail(payload)
      .then((result: any) => {
        //this.isUserRegistered = true;
        this.customerFirstName = '';
        this.customerLastName = '';
        this.customerPhone = '';
      })
      .catch((err: any) => {
        logger.error(err);
      })
      .finally(() => {
        this.isEmailSending = false;
      });
  }
  clickClose() {
    this.$emit('click:close');
  }
}
