import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import user, { User } from '@/shared/model/user';

const logger = new Logger('registration');
const userModule = namespace('user');

@Component({
  components: {},
})
export default class AddDaysDialog extends Vue {
  @Ref('registration-form')
  private refRegistrationForm!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  private userModel: string = '0';

  isFormValid = true;
  isSubmitted = false;

  days = '0';

  async mounted() {
    this.userModel = '0';
  }

  //#region Validation rules

  showDaysError = '';
  get daysRules() {
    return [
      (value: any) => {
        if (value == '') {
          this.showDaysError = this.$i18n.t('messages.days_required').toString();
          return false;
        } else {
          this.showDaysError = '';
          return true;
        }
      },
    ];
  }
  //#endregion

  async registerUser() {
    const isFormValidResult = await this.refRegistrationForm.validate();

    if (isFormValidResult) {
      this.userModel = this.days;
      this.$emit('click:update', this.days);
    }
  }

  clickClose() {
    this.$emit('click:close');
  }
}
